<template>
  <div class="current">
    <div class="list">
      <div class="list-item" v-for="(item, i) in list" :key="i" @click="showInfo(item.id, item.is_apply)">
        <img class="list-item-img" :src="$host+item.logo" />
        <div class="hospital-title">{{ item.title }}</div>
        <div class="hospital-tel">{{ item.tel != "" ? item.tel : "无联系方式" }}</div>
        <div class="hospital-address">
          {{ item.address != "" ? item.address : "无地址信息" }}
        </div>
      </div>
      <div class="no-data" v-show="total == 0">
        <div>
          <img
            src="../../../static/my/empty-image-default.png"
            style="height: 350px; width: auto"
          />
          <div style="text-align: center">暂无数据</div>
        </div>
      </div>
    </div>
    <div v-show="total > 6">
      <b-pagination
        v-model="params.page"
        :total-rows="total"
        :per-page="params.limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        limit: 6,
        page: 1,
        city_id: "",
        search: "",
      },
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.getHospital()
  },
  methods: {
    getHospital() {
      let data = this.params;
      this.$request.applyList(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          console.log(data);
          this.list = data.items;
          this.total = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    showInfo(id, is_apply) {
        this.$router.push({
            path: "/doctorInfo/hospitalinfo",
            query: {
                id: id,
                is_apply: is_apply    
            }
        })
    }
  },
};
</script>
<style scoped lang="scss">
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  min-height: 648px;
  .no-data {
    width: 100%;
    height: 648px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-item {
    width: 291.3px;
    margin-right: 12px;
    height: 314px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    .list-item-img {
      width: 291.3px;
      height: 204px;
      object-fit: cover;
      border-radius: 4px;
    }
    .hospital-title {
      font-size: 20px;
      font-weight: bold;
      width: 280.3px;
      margin-left: 5px;
      height: 40px;
      line-height: 40px;
    }
    .hospital-tel {
      color: #666;
      height: 32px;
      line-height: 32px;
      padding-left: 5px;
    }
    .hospital-address {
      color: #999;
      width: 280.3px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .list-item:hover {
    cursor: pointer;
  }
}
</style>
