<template>
  <div class="page">
    <div class="header">
      <div
        class="header-nav"
        :class="tab == 0 ? 'header-nav-active' : ''"
        @click="tab = 0"
      >
        选择入驻医院
      </div>
      <div
        class="header-nav"
        :class="tab == 1 ? 'header-nav-active' : ''"
        @click="tab = 1"
      >
        我的入驻
      </div>
    </div>
    <div class="content">
      <the-hospital v-show="tab == 0"></the-hospital>
      <my-hospital v-show="tab == 1"></my-hospital>
    </div>
  </div>
</template>
<script>
import MyHospital from "./hosp/myhospital.vue";
import TheHospital from "./hosp/thehospital.vue";

export default {
  components: {
    TheHospital,
    MyHospital,
  },
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.page {
  width: 910px;
  padding: 10px 0;
  min-height: 620px;
  background: #f9fbfb;
}
.header {
  height: 56px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  display: flex;
  padding: 0 10px;
  align-items: center;
  color: #999;
  .header-nav {
    width: 128px;
    text-align: center;
  }
  .header-nav:hover {
    cursor: pointer;
  }
  .header-nav-active {
    color: #fd9852;
    font-weight: bold;
  }
}
.content {
  margin-top: 20px;
}
.current {
  .select-area {
    height: 50px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .city-area {
      width: 120px;
      text-align: center;
    }
    .city-area:hover {
      cursor: pointer;
    }
    .search-area {
      width: 500px;
      display: flex;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  min-height: 648px;
  .no-data {
    width: 100%;
    height: 648px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-item {
    width: 291.3px;
    margin-right: 12px;
    height: 314px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    .list-item-img {
      width: 291.3px;
      height: 204px;
      object-fit: cover;
      border-radius: 4px;
    }
    .hospital-title {
      font-size: 20px;
      font-weight: bold;
      width: 280.3px;
      margin-left: 5px;
      height: 40px;
      line-height: 40px;
    }
    .hospital-tel {
      color: #666;
      height: 32px;
      line-height: 32px;
      padding-left: 5px;
    }
    .hospital-address {
      color: #999;
      width: 280.3px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .list-item:hover {
    cursor: pointer;
  }
}
</style>
