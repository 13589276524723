<template>
  <div class="current">
    <div class="select-area">
      <div class="city-area" id="popover-target-h">
        {{ city }}
        <b-icon
          :icon="show ? 'caret-up-fill' : 'caret-down-fill'"
          style="color: #fd9852"
        ></b-icon>
      </div>
      <b-popover
        target="popover-target-h"
        placement="bottom"
        custom-class="popover-area"
        :show.sync="show"
      >
        <city @selectcity="selectcity"></city>
      </b-popover>
      <div class="search-area">
        <b-form-input
          style="width: 340px"
          v-model="params.search"
          placeholder="输入医院名称或关键字"
          @click="getHospital"
        ></b-form-input>
        <b-button
          variant="primary"
          style="width: 60px; background: #fd9852; border: none"
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </div>
    </div>
    <div class="list">
      <div
        class="list-item"
        v-for="(item, i) in list"
        :key="i"
        @click="showInfo(item.id, item.is_apply)"
      >
        <img class="list-item-img" :src="$host + item.logo" />
        <div class="hospital-title">{{ item.title }}</div>
        <div class="hospital-tel">{{ item.tel != "" ? item.tel : "无联系方式" }}</div>
        <div class="hospital-address">
          {{ item.address != "" ? item.address : "无地址信息" }}
        </div>
      </div>
      <div class="no-data" v-show="total == 0">
        <div>
          <img
            src="../../../static/my/empty-image-default.png"
            style="height: 350px; width: auto"
          />
          <div style="text-align: center">暂无数据</div>
        </div>
      </div>
    </div>
    <div v-show="total > 6">
      <b-pagination
        v-model="params.page"
        :total-rows="total"
        :per-page="params.limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import city from "../../../utils/city.vue";
export default {
  components: {
    city,
  },
  watch: {
    "params.page": function () {
      this.getHospital();
    },
  },
  data() {
    return {
      show: false,
      city: "全国",
      params: {
        limit: 6,
        page: 1,
        city_id: "",
        search: "",
      },
      list: [],
      total: 0,
    };
  },
  methods: {
    selectcity(option) {
      console.log(option);
      this.city = option.name;
      this.show = false;
      if (option.id) {
        this.params.city_id = option.id;
      } else {
        this.params.city_id = "";
      }
      this.getHospital();
    },
    getHospital() {
      let data = this.params;
      this.$request.gethospitallist(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          console.log(data);
          this.list = data.items;
          this.total = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    showInfo(id, is_apply) {
      this.$router.push({
        path: "/doctorInfo/hospitalinfo",
        query: {
          id: id,
          is_apply: is_apply,
        },
      });
    },
  },
  mounted() {
    this.getHospital();
  },
};
</script>
<style scoped lang="scss">
.current {
  .select-area {
    height: 50px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .city-area {
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
    .city-area:hover {
      cursor: pointer;
    }
    .search-area {
      width: 500px;
      display: flex;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  min-height: 648px;
  .no-data {
    width: 100%;
    height: 648px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-item {
    width: 291.3px;
    margin-right: 12px;
    height: 314px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    .list-item-img {
      width: 291.3px;
      height: 204px;
      object-fit: cover;
      border-radius: 4px;
    }
    .hospital-title {
      font-size: 20px;
      font-weight: bold;
      width: 280.3px;
      margin-left: 5px;
      height: 40px;
      line-height: 40px;
    }
    .hospital-tel {
      color: #666;
      height: 32px;
      line-height: 32px;
      padding-left: 5px;
    }
    .hospital-address {
      color: #999;
      width: 280.3px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .list-item:hover {
    cursor: pointer;
  }
}
</style>
